<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__title">{{hasEditType ? 'Edit' : 'Create'}} translate modules</div>
					</div>

					<div class="lang">
						<div class="lang__tabs">
							<div class="lang__tab" v-for="lang in languages"
									:class="{'has-active': lang.code === selectedLang}"
									@click="selectedLang = lang.code">{{lang.code}}</div>
						</div>
						<div class="lang__cnt">
							<div class="lang__form" v-for="lang in languages" v-if="lang.code === selectedLang">
								<div class="grid">
									<div class="grid__coll grid__coll--6">
										<div class="form__item">
											<form-textarea v-model="formData.question[lang.code]"
													name="question"
													@enter="onSave"
													label="Question"></form-textarea>
										</div>

										<div class="form__item">
											<form-textarea v-model="formData.answer[lang.code]"
													name="answer"
													@enter="onSave"
													label="Answer"></form-textarea>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="form__item">
						<form-select-tags v-model="formData.related"
								class="has-sm"
								:options="servicesOptions"
								label="Display in such services as"
								option_label="title"
								name="related"></form-select-tags>
					</div>

					<div class="form__item">
						<form-checkbox v-model="formData.is_common"
								label="Is common"
								name="is_common"></form-checkbox>
					</div>

					<div class="form__footer">
						<button class="btn"
								:class="{'has-loading': loadingSaveItem}"
								@click="onSave"><span>{{hasEditType ? 'Edit' : 'Create'}}</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'faqs-save',
		props: ['item'],
		data(){
			return{
				selectedLang : this.$store.state.selectedLang,

				formData:{
					question: {},
					answer: {},
					related: null,
					is_common: 0,
				}
			}
		},

		computed:{
			...mapState({
				loadingSaveItem: state => state.faqs.loadingSaveItem,
				languages: state => state.languages.list,
				services: state => state.services.list
			}),

			hasEditType(){
				return !!this.item;
			},

			servicesOptions(){
				return $fn.map(this.services, service => {
					return {
						id: service.id,
						title: `${service.game.title} - ${service.translations.title ? service.translations.title[this.selectedLang] : 'NO_TRANSLATE'}`
					}
				})
			},
		},

		created(){
			if(this.hasEditType) this.setFormData()
		},

		methods:{
			...mapActions({
				SAVE_ITEM: 'faqs/SAVE_ITEM'
			}),

			onSave(){
				this.$validator.validate().then(result => {
					if (result) {
						let formData = $fn.cloneDeep(this.formData);

						this.SAVE_ITEM($fn.assign(formData,{
							id: this.hasEditType ? this.item.id : null,
							is_common: +formData.is_common,
							related:{
								service: $fn.map(formData.related, el => {
									return el.id
								})
							}
						})).then((resp) =>{
							this.$emit('close');

						}).catch(err => {
							$fn.setValidateErrors(err, this.errors);
						})
					}
				});
			},

			setFormData(){
				$fn.assign(this.formData, this.item);

				this.formData.related = this.item.related.services.length ? $fn.map(this.item.related.services, el => {
					let selectedService =  $fn.find(this.services, ['id', el]);
					return selectedService ? {
						id: selectedService.id,
						title: `${selectedService.game.title} - ${selectedService.translations.title[this.selectedLang]}`
					} : null

				}) : null;


			}
		}
	};
</script>

<style scoped lang=scss>

</style>
