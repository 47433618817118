<template>
	<div class="section">
		<div class="container">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">FAQS</div>

						<div class="section__options">
							<div class="section__option">
								<button class="btn btn--sm" @click="onSave(null)">Create</button>
							</div>
						</div>
					</div>

					<div class="section__sub">
						<div class="section__infoWrap">
							<div class="filter">
								<div class="filter__wrap">
									<div class="filter__item">
										<form-input v-model="search"
												name="search"
												label="Search"
												:filterType="true"></form-input>
									</div>

									<div class="filter__item">
										<form-select v-model="service_id"
												class="has-sm"
												:options="servicesOptions"
												name="service_id"
												option_label="title"
												label="Service"></form-select>
									</div>

									<div class="_flex _justify-end" v-if="hasFilterRun">
										<button class="btn btn--mini" @click="resetFilter">Reset</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="section__main">
						<div class="section__main">
							<v-table :headers="tabelHeaders"
									:items="list"
									:hasLoadList="loadingGetList"
									:pagination="listPagination"
									@changeLimit="onPaginationChangeLimit"
									@changePage="onPaginationChangePage">
								<tr v-for="(td, index) in list" :key="index">
									<td>{{td.id}}</td>
									<td>{{td.question[selectedLang]}}</td>
									<td>{{td.answer[selectedLang]}}</td>
									<td>
										<template v-if="td.related">
											<div class="faq__tabss">
												<span class="table__tab" v-for="item in td.related.services">
													{{servicesMapById[item]}}
														<!--{{servicesMapById[item].game.title}} - -->
														<!--{{servicesMapById[item].translations.title[selectedLang]}}-->
												</span>
											</div>
										</template>
									</td>
									<td>
										<span class="table__tab" v-if="td.is_common">Common</span>
									</td>
									<td>
										<div class="table__options">
											<div class="table__option" @click="onSave(td)">
												<v-icon name="edit"></v-icon>
											</div>

											<div class="table__option">
												<alert-modal :class="{'has-disabled': loadingDeleteItem}" @success="onDelete(td.id)">
													<v-icon name="trash-alt"></v-icon>
												</alert-modal>
											</div>
										</div>
									</td>
								</tr>
							</v-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import FaqsSave from './FaqsSave';

	export default {
		name: 'faqs-index',
		data(){
			return {
				tabelHeaders: [
					{key:'id', text: 'ID'},
					{key:'question', text: 'Question'},
					{key:'answer', text: 'Answer'},
					{key:'type', text: 'Display in such services', type: 'tabs'},
					{key:'common', text: 'Is common'},
					{key:'nav', text: ''},
				]
			}
		},

		computed:{
			...mapState({
				list: state => state.faqs.list,
				listPagination: state => state.faqs.listPagination,
				listFilter: state => state.faqs.listFilter,
				loadingGetList: state => state.faqs.loadingGetList,
				loadingDeleteItem: state => state.faqs.loadingDeleteItem,
				loadingGetServicesList: state => state.faqs.loadingGetServicesList,

				selectedLang: state => state.selectedLang,
				services: state => state.services.list
			}),

			...mapGetters({
				listActiveFilter: 'faqs/listActiveFilter',
				servicesMapById: 'services/servicesMapById'
			}),

			servicesOptions(){
				return $fn.map(this.services, service => {
					return {
						id: service.id,
						title: `${service.game.title} - ${service.translations.title ? service.translations.title[this.selectedLang] : 'NO_TRANSLATE'}`
					}
				})
			},

			search:{
				get(){
					return this.listFilter.search
				},
				set(val){
					this.SET_FILTER_LIST({key: 'search', val: val});
					this.getList();
				}
			},

			service_id: {
				get () {
					let selectedService =  $fn.find(this.services, ['id', +this.listFilter['service_id']]);
					return selectedService ? {
						id: selectedService.id,
						title: `${selectedService.game.title} - ${selectedService.translations.title[this.selectedLang]}`
					} : null
				},
				set (val) {
					this.SET_FILTER_LIST({key: 'service_id', val: val ? val.id : null});
					this.getList();
				}
			},

			hasFilterRun(){
				return this.search || this.service_id;
			}
		},

		created(){
			this.initCommonData();

			this.getFilterDataFromUrl();
			this.getList();
		},

		methods:{
			...mapMutations({
				SET_FILTER_LIST: 'faqs/SET_FILTER_LIST'
			}),
			...mapActions({
				GET_LIST: 'faqs/GET_LIST',
				GET_ITEM: 'faqs/GET_ITEM',
				DELETE_ITEM: 'faqs/DELETE_ITEM',
				GET_SERVICES: 'services/GET_LIST',
			}),

			onSave(item){
				if(item){
					this.GET_ITEM(item.id).then(reps => {
						this.openModalSave(reps.item);
					})

				} else {
					this.openModalSave(null)
				}

			},

			openModalSave(item){
				this.$modal.show(FaqsSave,{
					item: item,
				}, $MODAL_OPTIONS.default);
			},

			onDelete(id){
				this.DELETE_ITEM(id);
			},

			getList: $fn.debounce(function () {
				this.GET_LIST();
				this.setFilterDataToUrl();
			}, 500),

			getFilterDataFromUrl(){
				let query = this.$route.query;

				$fn.forIn(query,(val, key) => {
					this.SET_FILTER_LIST({key: key, val: val});
				})
			},

			setFilterDataToUrl(){
				this.$router.replace({ name: this.$route.name, query: this.listActiveFilter }).catch(() => {});
			},

			onPaginationChangeLimit(limit){
				this.SET_FILTER_LIST({key: 'limit', val: limit});
				this.getList();
			},

			onPaginationChangePage(page){
				this.SET_FILTER_LIST({key: 'page', val: page});
				this.getList();
			},

			initCommonData(){
				this.GET_SERVICES();
			},

			resetFilter(){
				this.search = null;
				this.service_id = null;
			}
		}
	};
</script>

<style scoped lang=scss>
	.faq{
		&__tabss{
			max-width: 250px;
		}
	}
</style>
